<template>
  <div class="prodton">

    <el-card class="my-card">
      <div slot="header">
        <span style="font-size: 20px;font-weight: bolder;color:#FFA500">生产</span>
      </div>
      <div class="Logistics-warning">
        <div>
          <el-table
            :data="tableData1"
            border
            v-loading="isLoading"
            style="width: 100%;font-size: 20px;font-weight: bolder">
            <el-table-column type="index" label="编号" width="80"></el-table-column>
            <el-table-column prop="productNo" label="生产编码" width="260"></el-table-column>
<!--            <el-table-column prop="productType" label="生产类型">-->
<!--              <template slot-scope="scope">-->
<!--                <span>{{ scope.row.productType | productTypeFormate }}</span>-->
<!--              </template>-->
<!--            </el-table-column>-->
            <el-table-column prop="product" label="产品名称"></el-table-column>
            <el-table-column prop="weight" label="重量:KG"></el-table-column>
            <el-table-column prop="need" label="需要楼层">
              <template slot-scope="scope">
                <span>{{ scope.row.need | needStatusFormate}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="mark" label="备注"></el-table-column>
            <el-table-column prop="productStatus" label="生产状态">
              <template slot-scope="scope">
                <span>{{ scope.row.productStatus | productStatusFormate }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="releasTime" label="发布时间">
              <template slot-scope="scope">
                <span>{{ scope.row.releasTime | dateFormate }}</span>
              </template>
            </el-table-column>
            <el-table-column label="操作">
              <template slot-scope="{row}">
                <el-button @click="prod(row)" type="warning" size="mini" v-if="'19'===row.productStatus">准备生产</el-button>
                <el-button @click="finish(row)" type="primary" size="mini" v-if="'23'===row.productStatus">完成生产</el-button>
              </template>
            </el-table-column>

          </el-table>
        </div>
        <!--      分页组件结构-->
        <el-pagination
          @size-change="handleSizeChange1"
          @current-change="handleCurrentChange1"
          :current-page="form1.currentPage"
          :page-sizes="[5,10,50,100]"
          :page-size="form1.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="dataTotal1"
          :disabled="isLoading"
        >
        </el-pagination>
      </div>
    </el-card>
    <el-card class="my-card">
      <div slot="header">
        <span style="font-size: 20px;font-weight: bolder;color:#FFA500">调色</span>
      </div>
      <div class="Logistics-warning">
        <div>
          <el-table
            :data="tableData2"
            border
            v-loading="isLoading"
            style="width: 100%;font-size: 20px;font-weight: bolder">
            <el-table-column type="index" label="编号" width="80"></el-table-column>
            <el-table-column prop="productNo" label="生产编码" width="260"></el-table-column>
<!--            <el-table-column prop="productType" label="生产类型">-->
<!--              <template slot-scope="scope">-->
<!--                <span>{{ scope.row.productType | productTypeFormate }}</span>-->
<!--              </template>-->
<!--            </el-table-column>-->
            <el-table-column prop="product" label="产品名称"></el-table-column>
            <el-table-column prop="weight" label="重量:KG"></el-table-column>
            <el-table-column prop="need" label="需要楼层">
              <template slot-scope="scope">
                <span>{{ scope.row.need | needStatusFormate}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="mark" label="备注"></el-table-column>
            <el-table-column prop="productStatus" label="生产状态">
              <template slot-scope="scope">
                <span>{{ scope.row.productStatus | productStatusFormate }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="releasTime" label="发布时间">
              <template slot-scope="scope">
                <span>{{ scope.row.releasTime | dateFormate }}</span>
              </template>
            </el-table-column>
            <el-table-column label="操作">
              <template slot-scope="{row}">
                <el-button @click="prod(row)" type="warning" size="mini" v-if="'19'===row.productStatus">准备生产</el-button>
                <el-button @click="finish(row)" type="primary" size="mini" v-if="'23'===row.productStatus">完成生产</el-button>
              </template>
            </el-table-column>

          </el-table>
        </div>
        <!--      分页组件结构-->
        <el-pagination
          @size-change="handleSizeChange2"
          @current-change="handleCurrentChange2"
          :current-page="form2.currentPage"
          :page-sizes="[5,10,50,100]"
          :page-size="form2.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="dataTotal2"
          :disabled="isLoading"
        >
        </el-pagination>
      </div>
    </el-card>
    <el-card class="my-card">
      <div slot="header">
        <span style="font-size: 20px;font-weight: bolder;color:#51cf66">待出库</span>
      </div>
      <div class="Logistics-warning">
        <div>
          <el-table
            :data="tableData3"
            border
            v-loading="isLoading"
            style="width: 100%;font-size: 20px;font-weight: bolder">
            <el-table-column type="index" label="编号" width="80"></el-table-column>
            <el-table-column prop="productNo" label="生产编码" width="260"></el-table-column>
            <!--            <el-table-column prop="productType" label="生产类型">-->
            <!--              <template slot-scope="scope">-->
            <!--                <span>{{ scope.row.productType | productTypeFormate }}</span>-->
            <!--              </template>-->
            <!--            </el-table-column>-->
            <el-table-column prop="product" label="产品名称"></el-table-column>
            <el-table-column prop="weight" label="重量:KG"></el-table-column>
            <el-table-column prop="need" label="需要楼层">
              <template slot-scope="scope">
                <span>{{ scope.row.need | needStatusFormate}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="mark" label="备注"></el-table-column>
            <el-table-column prop="productStatus" label="生产状态">
              <template slot-scope="scope">
                <span>{{ scope.row.productStatus | productStatusFormate }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="finishTime" label="生产完成时间">
              <template slot-scope="scope">
                <span>{{ scope.row.finishTime | dateFormate }}</span>
              </template>
            </el-table-column>
            <el-table-column label="操作">
              <template slot-scope="{row}">
                <el-button @click="out(row)" type="success" size="mini" v-if="'27'===row.productStatus">出库</el-button>
              </template>
            </el-table-column>

          </el-table>
        </div>
        <!--      分页组件结构-->
        <el-pagination
          @size-change="handleSizeChange3"
          @current-change="handleCurrentChange3"
          :current-page="form3.currentPage"
          :page-sizes="[5,10,50,100]"
          :page-size="form3.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="dataTotal3"
          :disabled="isLoading"
        >
        </el-pagination>
      </div>
    </el-card>

    <el-card class="my-card">
      <div slot="header">
        <span style="font-size: 20px;font-weight: bolder;color:#51cf66">状态查询</span>

      </div>
      <el-form :inline="true" ref="form4" :model="form4" class="demo-form-inline" size="small">
        <el-form-item label="生产状态：" prop="productStatus">
          <el-select v-model="form4.productStatus" placeholder="全部" clearable>
            <el-option v-for="item in productStatusList" :key="item.status" :label="item.statusDesc"
                       :value="item.status"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="需要楼层：" prop="need">
          <el-select v-model="form4.need" clearable>
            <el-option v-for="item in needStatusList" :key="item.status" :label="item.statusDesc"
                       :value="item.status"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="通知出库时间：">
          <el-date-picker
            v-model="form4.dateQuery4"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            format='yyyy-MM-dd HH:mm:ss' value-format='yyyy-MM-dd HH:mm:ss' >
          </el-date-picker>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="onSubmit" size="mini" :disabled="isLoading">查询</el-button>
        </el-form-item>

      </el-form>
      <div class="Logistics-warning">
        <div>
          <el-table
            :data="tableData4"
            border
            v-loading="isLoading"
            style="width: 100%;font-size: 20px;font-weight: bolder"
            size="medium"
            :row-style="{height:'40px'}"
            :cell-style="{padding:'2px'}">
            <el-table-column type="index" label="编号" width="80"></el-table-column>
            <el-table-column prop="productNo" label="生产编码" ></el-table-column>
            <el-table-column prop="product" label="产品名称"></el-table-column>
            <el-table-column prop="weight" label="重量:KG"></el-table-column>
            <el-table-column prop="need" label="需要楼层">
              <template slot-scope="scope">
                <span>{{ scope.row.need | needStatusFormate}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="mark" label="备注"></el-table-column>
            <el-table-column prop="productStatus" label="生产状态">
              <template slot-scope="scope">
                <span>{{ scope.row.productStatus | productStatusFormate }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="outTime" label="通知出库时间">
              <template slot-scope="scope">
                <span>{{ scope.row.outTime | dateFormate }}</span>
              </template>
            </el-table-column>

          </el-table>
        </div>
        <!--      分页组件结构-->
        <el-pagination
          @size-change="handleSizeChange4"
          @current-change="handleCurrentChange4"
          :current-page="form4.currentPage"
          :page-sizes="[10,50,100]"
          :page-size="form4.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="dataTotal4"
          :disabled="isLoading"
        >
        </el-pagination>
      </div>
    </el-card>
  </div>
</template>

<script>
import {dateFormate} from '@/utils/dateUtil'
import {getUserInfo} from '@/services/user'
import {findReleasProductInfoByPage,prod,finish,out,findAllProductInfoByPage} from "@/services/produce";
import {productStatusToCn,productTypeToCn,needStatusToCn} from '@/utils/enums'
import {findAllProductStatus,findAllNeedStatus} from '@/services/status'
export default {
  beforeDestroy() {
    clearInterval(this.timer);
  },
  mounted() {
    this.timer = setInterval(() => {
      this.loadUserInfo()
      this.loadProductStatus()
      this.loadNeedStatus()
      this.loadReleasProductInfoByPage1()
      this.loadReleasProductInfoByPage2()
      this.loadReleasProductInfoByPage3()
      this.loadReleasProductInfoByPage4()
    }, 60*1000);

  },
  name: 'prodton',
  data() {
    return {
      timer: '',
      productStatusList: [],
      needStatusList: [],
      userInfo:{},
      // 用于存储资源列表数据
      tableData1: [],
      tableData2: [],
      tableData3: [],
      tableData4: [],
      form1: {
        // 当前显示页号
        currentPage: 1,
        pageSize: 5,
        productStatus: '19,23'
      },
      form2: {
        // 当前显示页号
        currentPage: 1,
        pageSize: 5,
        productStatus: '19,23'
      },
      form3: {
        // 当前显示页号
        currentPage: 1,
        pageSize: 5,
        productStatus: '27'
      },
      form4: {
        // 当前显示页号
        currentPage: 1,
        pageSize: 10,
        productStatus: '31'
      },
      // 数据总数
      dataTotal1: 0,
      dataTotal2: 0,
      dataTotal3: 0,
      dataTotal4: 0,
      isLoading: false,

    }
  },
  methods: {
    // 添加筛选后的数据查询
    async onSubmit() {
      // 请求数据前，将请求的页数更新为1
      this.form4.currentPage = 1
      await this.loadReleasProductInfoByPage4()
    },
    async loadProductStatus() {
      const {data} = await findAllProductStatus()
      if (200 === data.code) {
        this.productStatusList = data.data
      } else {
        this.$message.error('查询状态列表失败')
      }
    },
    async loadNeedStatus() {
      const {data} = await findAllNeedStatus()
      if (200 === data.code) {
        this.needStatusList = data.data
      } else {
        this.$message.error('查询需方列表失败')
      }
    },
    async loadUserInfo() {
      const {data} = await getUserInfo()
      if (data.code === 200) {
        return this.userInfo = data.data
      }
      this.$message.error('获取用户信息失败')
    },
    async prod(row) {
      const params = {
        productNo: row.productNo,
        tackName: this.userInfo.userName,
        tackPhone: this.userInfo.phone
      }
      const {data} = await prod(params)
      if (200 === data.code) {
        this.$message.success("操作成功")
      } else {
        this.$message.warning("操作失败")
      }
      await this.loadReleasProductInfoByPage1()
      await this.loadReleasProductInfoByPage2()
      await this.loadReleasProductInfoByPage3()
      await this.loadReleasProductInfoByPage4()
    },
    async finish(row) {
      const params = {
        productNo: row.productNo,
        tackName: this.userInfo.userName,
        tackPhone: this.userInfo.phone
      }
      const {data} = await finish(params)
      if (200 === data.code) {
        this.$message.success("操作成功")
      } else {
        this.$message.warning("操作失败")
      }
      await this.loadReleasProductInfoByPage1()
      await this.loadReleasProductInfoByPage2()
      await this.loadReleasProductInfoByPage3()
      await this.loadReleasProductInfoByPage4()
    },
    async out(row) {
      const params = {
        productNo: row.productNo,
        tackName: this.userInfo.userName,
        tackPhone: this.userInfo.phone
      }
      const {data} = await out(params)
      if (200 === data.code) {
        this.$message.success("操作成功")
      } else {
        this.$message.warning("操作失败")
      }
      await this.loadReleasProductInfoByPage1()
      await this.loadReleasProductInfoByPage2()
      await this.loadReleasProductInfoByPage3()
      await this.loadReleasProductInfoByPage4()
    },

    // 每页显示条数变化时触发
    handleSizeChange1(val) {
      this.form1.pageSize = val
      // 由于修改了每页显示的条数，应当将页数还原默认值1
      this.form1.currentPage = 1
      this.loadReleasProductInfoByPage1()
    },
    // 页号改变时触发
    handleCurrentChange1(val) {
      this.form1.currentPage = val
      this.loadReleasProductInfoByPage1()
    },
    // 每页显示条数变化时触发
    handleSizeChange2(val) {
      this.form2.pageSize = val
      // 由于修改了每页显示的条数，应当将页数还原默认值1
      this.form2.currentPage = 1
      this.loadReleasProductInfoByPage2()
    },
    // 页号改变时触发
    handleCurrentChange2(val) {
      this.form2.currentPage = val
      this.loadReleasProductInfoByPage2()
    },
    // 每页显示条数变化时触发
    handleSizeChange3(val) {
      this.form3.pageSize = val
      // 由于修改了每页显示的条数，应当将页数还原默认值1
      this.form3.currentPage = 1
      this.loadReleasProductInfoByPage3()
    },
    // 页号改变时触发
    handleCurrentChange3(val) {
      this.form3.currentPage = val
      this.loadReleasProductInfoByPage3()
    },
    // 每页显示条数变化时触发
    handleSizeChange4(val) {
      this.form4.pageSize = val
      // 由于修改了每页显示的条数，应当将页数还原默认值1
      this.form4.currentPage = 1
      this.loadReleasProductInfoByPage4()
    },
    // 页号改变时触发
    handleCurrentChange4(val) {
      this.form4.currentPage = val
      this.loadReleasProductInfoByPage4()
    },
    async loadReleasProductInfoByPage1() {
      // 开始加载数据 生产模块
      this.isLoading = true
      this.form1.productType = '1'
      const {data} = await findReleasProductInfoByPage(this.form1)
      if (200 === data.code) {
        this.tableData1 = data.data.records
        this.dataTotal1 = data.data.total
      }
      this.isLoading = false
    },
    async loadReleasProductInfoByPage2() {
      // 开始加载数据 调色模块
      this.isLoading = true
      this.form2.productType = '2'
      const {data} = await findReleasProductInfoByPage(this.form2)
      if (200 === data.code) {
        this.tableData2 = data.data.records
        this.dataTotal2 = data.data.total
      }
      this.isLoading = false
    },
    async loadReleasProductInfoByPage3() {
      // 开始加载数据 调色模块
      this.isLoading = true
      const {data} = await findReleasProductInfoByPage(this.form3)
      if (200 === data.code) {
        this.tableData3 = data.data.records
        this.dataTotal3 = data.data.total
      }
      this.isLoading = false
    },
    async loadReleasProductInfoByPage4() {
      this.isLoading = true
      if (this.form4 && this.form4.dateQuery4) {
        let outDateBegin = this.form4.dateQuery4[0]
        let outDateEnd = this.form4.dateQuery4[1]
        this.form4.outDateBegin = outDateBegin
        this.form4.outDateEnd = outDateEnd
      }else {
        this.form4.outDateBegin = ""
        this.form4.outDateEnd = ""
      }
      const {data} = await findAllProductInfoByPage(this.form4)
      if (200 === data.code) {
        this.tableData4 = data.data.records
        this.dataTotal4 = data.data.total
      }
      this.isLoading = false
    },
  },
  created() {
    this.loadUserInfo()
    this.loadProductStatus()
    this.loadNeedStatus()
    this.loadReleasProductInfoByPage1()
    this.loadReleasProductInfoByPage2()
    this.loadReleasProductInfoByPage3()
    this.loadReleasProductInfoByPage4()

  },
  filters: {
    dateFormate(date) {
      return dateFormate(date)
    },
    productStatusFormate(code) {
      return productStatusToCn(code)
    },
    productTypeFormate(code) {
      return productTypeToCn(code)
    },

    needStatusFormate(code) {
      return needStatusToCn(code)
    },

    // topStatusFormate(code) {
    //   return topStatusToCn(code)
    // },


  }
}
</script>

<style lang="scss" scoped>
.my-card {
  margin-bottom: 10px;
}
</style>
